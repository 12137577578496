.nodes-manager {
    gap: 32px;
    flex-direction: row;
    display: flex;
    max-width: calc(100% - 32px);
    margin: 0 auto;
}

.nodes-manager > form {
    flex-grow: 1;
}

.form-submit-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.form-submit {
    margin-left: 20px;
}

.input-short {
    width: 255px;
}

.offset-input {
    margin: 16px 0;
}

.nodes-list__item  {
    padding: 3px 0;
}

.nodes-list__item th {
    text-align: center;
}

.nodes-list tbody th:first-child {
    display: flex;
    align-content: center;
    text-align: left;
    padding-left: 10px;
}

.node-list__title {
    width: fit-content;
}

.node-list__title_selected {
    color: #3568DD;
}

.node-list__title:not(.node-list__title_manager) {
    text-align: left;
    margin-bottom: 15px;
    cursor: pointer;
}

.node-list__title_manager:not(:last-child) {
    margin-right: 5px;
}

.reset-font-family {
    font-family: sans-serif;
}

.nodes-list, .nodes-list th {
    border-bottom: 1px solid black;
    border-collapse: collapse;
}

.copy-btn {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.index-number {
    position: absolute;
    left: -20px;
}

.position-relative {
    position: relative;
}

.stake-heading {
    text-align: center;
}

.manager-btn:not(:last-child) {
    margin-right: 5px;
}

.ui-form:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid gainsboro;
}