.control {
    display: block;
    position: relative;
    padding-left: 34px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 12px;
    color: #A2B2D8;
    font-family: Inter, sans-serif;
}
.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.control_indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 24px;
    width: 24px;
    border: 1px solid #A2B2D8;
    border-radius: 6px;
}

.control input:checked ~ .control_indicator {
    border-color: #3568DD;
}
.control input:checked ~ span {
    color: #3568DD;
}

.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}

.control input:checked ~ .control_indicator:after {
    display: block;
}

.control-checkbox .control_indicator:after {
    left: 8px;
    top: 3px;
    width: 5px;
    height: 11px;
    border: solid #3568DD;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.control-checkbox input:disabled ~ .control_indicator:after {
    border-color: #7b7b7b;
}