.transactions-page {
    color: #1D1D1D;
    row-gap: unset !important;
}

.transactions-page__header {
    padding-bottom: 16px;
    border-bottom: 1px solid #EDF3FF;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.confirmation-tabs {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    column-gap: 32px;
}

.confirmation-tabs__btn {
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
    color: #1D1D1D;
    text-transform: capitalize;
}

.confirmation-tabs__btn_selected {
    color: #3568DD;
}

.transaction-item {
    border-bottom: 1px solid #EDF3FF;
    padding: 16px 0;
}

.transaction-item__core .transaction-item__mark {
    background: #16C784;
}

.transaction-item__list {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 8px;
    margin-bottom: 16px;
}

.transaction-item__list-item {
    min-height: 24px;
    display: flex;
    flex-flow: row nowrap;
}

.transaction-item__mark {
    width: fit-content;

    padding: 0 8px;
    background: #F2C037;
    border-radius: 6px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.transaction-item__title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: #1D1D1D;
    text-transform: capitalize;
}

.transaction-item__value {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #1D1D1D;
    white-space: break-spaces;
    padding-left: 8px;
}

.transaction-item__linkish {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    text-decoration: underline;

    color: #3568DD;
    cursor: pointer;
}

.transaction-item__arguments {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;

    color: #A2B2D8;
    white-space: pre-wrap;
}

.transaction-item__argument-name {
    text-transform: capitalize;
    font-weight: 600;
}

.transaction-item__footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.transaction-item__approvals-amount {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #1D1D1D;
}

.transaction-item__button {
    height: 32px;
    padding: 0 16px;

    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
}
