body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #edf3ff;
  min-height: 100vh;
}

.content {
  padding: 16px 0 0 0;
}

p,
span,
ul,
li {
  margin: 0;
  padding: 0;
}

button {
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

p,
span {
  line-height: 24px;
}

li {
  list-style: none;
}

input[type='checkbox'] {
  margin-right: 7px;
}

.container {
  max-width: calc(100% - 336px);
  margin-left: auto;
  top: 0;
  width: 100%;
  z-index: 100;
}

.nav-menu__logo {
  margin-bottom: 32px;
}

.nav-menu {
  overflow: auto;
  height: calc(100vh - 32px);
  width: 320px;
  background: #ffffff;
  box-shadow: 0px 8px 49px rgba(162, 178, 216, 0.65);
  border-radius: 8px;
  padding: 32px;
  margin: 16px;
  top: 0;
  left: 0;
  position: fixed;
  color: #1d1d1d;
}

.nav-menu__label {
  font-size: 12px;
  margin-bottom: 2px;
  font-weight: 600;
}

.nav-menu__link {
  color: #3568dd;
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 12px;
}

.nav-menu__address {
  font-size: 12px;
}

.nav-menu__access {
  font-size: 12px;
  padding-left: 22px;
  position: relative;
}

.nav-menu__access:before {
  content: '';
  position: absolute;
  height: 14px;
  width: 14px;
  background: #fe636d;
  border-radius: 50%;
  left: 0;
  top: 5px;
}

.nav-menu__access_true:before {
  background: #16c784;
}

.nav-menu__options-label {
  margin: 64px 0 18px;
  font-weight: 600;
}

.nav-menu__group-item-btn {
  border-top: 1px solid #edf3ff;
  padding: 18px 0;
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav-menu__group-item-arrow {
  margin-left: auto;
}

.nav-menu__group-item-arrow_open {
  transform: rotate(180deg);
}

.nav-menu__option {
  color: #a2b2d8;
  margin-bottom: 16px;
  cursor: pointer;
  text-decoration: none;
  display: block;
  width: fit-content;
}

.nav-menu__option_selected {
  color: #3568dd;
}

.nav-menu__options {
  height: auto;
  max-height: 0;
  transition: 0.3s;
  overflow: hidden;
}

.nav-menu__options_expanded {
  max-height: 1000px;
}

.nav-menu__access_true:before {
  background: #16c784;
}

.page {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 16px;
  background: #fff;
  max-width: 640px;
  box-shadow: 0px 8px 49px rgba(162, 178, 216, 0.65);
  border-radius: 8px;
  padding: 32px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.page__row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.page_wide {
  max-width: calc(100% - 32px);
}

.page h3 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #1d1d1d;
  padding: 0;
  margin: 0;
}

.ui-input, .form-control {
  height: 44px;
  width: 100%;
  padding: 10px;
  background: #edf3ff;
  border: none;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ui-input:read-only,
.ui-input:disabled {
  background: #ffffff;
  border: 1px solid #a2b2d8;
  user-select: none !important;
}

.ui-input::placeholder {
  color: rgba(162, 178, 216, 1);
}

.ui-select {
  position: relative;
  height: 44px;
  width: 100%;
  background: #edf3ff;
  border: none;
  border-radius: 8px;
}

.ui-select__value {
  width: 100%;
  height: 100%;
  padding: 10px;
  color: #1d1d1d;
}

.ui-select__placeholder {
  color: #a2b2d8;
}

.ui-select__options {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 24px;

  background: white;
  box-shadow: 0 8px 49px rgba(162, 178, 216, 0.65);
  border-radius: 8px;

  overflow: hidden;
  max-height: 0;
  position: absolute;
}

.ui-select:hover > .ui-select__options {
  max-height: unset;
}

.ui-select__options > option {
  padding: 8px;
  width: 100%;
  color: #a2b2d8;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.ui-select__options > option:first-of-type {
  padding-top: 16px;
}

.ui-select__options > option:last-of-type {
  padding-bottom: 16px;
}

.ui-select__options > option:hover {
  color: #1d1d1d;
  transition: color 0.2s ease-in-out;
}

.page__input-name {
  display: block;
}

.confirmations__title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 8px;
}

.confirmations__item {
  border-top: 1px solid #edf3ff;
  padding: 8px 0;
}

.confirmations__table {
  border-spacing: 0;
  width: 100%;
}

.confirmations__btn {
  padding: 3px 20px;
  border: 2px solid #1d1d1d;
  color: #1d1d1d;
  border-radius: 24px;
  display: block;
  margin-left: auto;
  margin-top: -18px;
}

.confirmations__btn img {
  margin-left: 8px;
}

.confirmations__item-info {
  font-size: 14px;
  line-height: 24px;
}

.confirmations__args {
  width: 70%;
}

.confirmations__args-text {
  line-height: 24px;
  word-break: break-word;
  font-size: 11px;
}

.confirmations__args-expand {
  color: #3568dd;
  margin-left: 4px;
  cursor: pointer;
}

.no-method-page {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  row-gap: 22px;
  height: 100vh;
}

.no-method-page__icon {
  width: 28px;
  height: 36px;
}

.no-method-page__text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #a2b2d8;
}

.owners-page-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 16px 32px;
  margin-bottom: 16px;
}

.owners-page-heading button,
.heading-button {
  border: 2px solid #1d1d1d;
  border-radius: 24px;
  padding: 8px 16px;
}

.owners-page-content {
  margin-top: 16px;
  padding: 20px 16px;
  grid-row-gap: 0px;
}

.user-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #edf3ff;
  padding: 10px 0;
}

.user-list-item_wrap {
  flex-wrap: wrap;
}

.user-list-item_small {
  padding: 1px 0;
}

.user-list-item_small span {
  font-size: 12px !important;
}

.user-list-item__btn {
  cursor: pointer;
  border: 2px solid #1d1d1d;
  border-radius: 24px;
  font-size: 12px !important;
  padding: 3px 16px !important;
  height: auto !important;
  font-weight: 500 !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(69, 126, 255, 0.4);
}

.modal {
  max-height: calc(100vh - 100px);
  overflow: auto;
  max-width: 640px;
  padding: 32px;
  background: white;
  box-shadow: 0 8px 49px rgba(162, 178, 216, 0.65);
  border-radius: 8px;
  margin: 50px auto 0;
}

.modal__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__title {
  font-size: 18px;
  font-weight: 500;
}

.modal-label {
  margin: 32px 0 8px;
  display: block;
}

.roles-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.role-checkbox {
  width: 33%;
  margin-bottom: 24px;
}

.modal__actions-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-list-item span {
  font-size: 16px;
}

@media (max-width: 768px) {
  .container {
    max-width: none;
    margin-left: 0;
  }

  .nav-menu {
    height: auto;
    width: calc(100% - 32px);
    position: relative;
  }

  .content {
    padding: 0 16px 16px;
  }

  .user-list-item {
    font-size: 14px;
  }

  .user-list-item span {
    text-overflow: ellipsis;
    display: block;
    width: 70%;
    overflow: hidden;
  }

  .modal-overlay {
    padding: 16px;
  }

  .page_wide {
    max-width: none;
  }
}

@media (max-width: 630px) {
  .remove-admin-item {
    text-overflow: ellipsis;
    display: block;
    width: 50vw;
    overflow: hidden;
  }

  .role-checkbox {
    width: 50%;
  }
}

@media (max-width: 500px) {
  .role-checkbox {
    width: 100%;
  }
}

.tx-approved {
  color: #16c784;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-left: 20px;
  width: fit-content;
}

.delete-btn {
  display: flex;
  align-items: center;
  gap: 7px;
  color: #a2b2d8;
}

.inline-loader {
  display: inline-block;
  position: relative;
  width: 2.5rem;
  height: 0.375rem;
  margin-left: 0.5rem;
}

.inline-loader div {
  position: absolute;
  top: 0;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background: #457eff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.inline-loader div:nth-child(1) {
  left: 0.25rem;
  animation: inline-loader1 0.6s infinite;
}

.inline-loader div:nth-child(2) {
  left: 0.25rem;
  animation: inline-loader2 0.6s infinite;
}

.inline-loader div:nth-child(3) {
  left: 1rem;
  animation: inline-loader2 0.6s infinite;
}

.inline-loader div:nth-child(4) {
  left: 1.75rem;
  animation: inline-loader3 0.6s infinite;
}

@keyframes inline-loader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes inline-loader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes inline-loader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0.75rem, 0);
  }
}

th {
  font-weight: 400;
}

.form-box {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 16px;
}

.capitalized {
  text-transform: capitalize;
}

.column {
  display: flex;
  flex-direction: column;
}

.eclipse-list {
  width: fit-content;
  display: flex;
  gap: 20px;
}

.eclipse-list span {
  width: 150px;
}