.ui-button {
  height: 48px;
  width: fit-content;
  border: 2px solid #1d1d1d;
  border-radius: 24px;
  padding: 12px 24px;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1d1d1d;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  cursor: pointer;
  user-select: none;
}

.ui-button:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.ui-button.ui-button_loading {
  cursor: not-allowed;
}
