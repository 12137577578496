.tabs-heading {
    display: flex;
    flex-direction: row!important;
    align-items: center;
    padding: 0 32px!important;
}

.tabs-btn {
    font-weight: 500;
    font-size: 18px;
    margin-right: 32px;
    padding: 28px 0;
}

.tabs-btn_selected {
    color: #3568DD;
    border-bottom: 6px solid #3568DD;
    padding-bottom: 22px;
}

.manage-groups-item {
    height: 27px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.manage-groups-list {
    margin: 20px 0 30px;
}

.group-users {
    color: #A2B2D8;
    font-size: 12px;
    margin-top: 16px;
    display: flex;
}

.heading-button {
    margin-left: auto;
}

.group-users__initiator {
    font-weight: 600;
    margin-left: auto;
    margin-right: 180px;
}

.top-balance {
    grid-row-gap: 0;
    row-gap: 0;
    margin-top: 16px;
    margin-bottom: 16px;
}

.top-balance__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #EDF3FF;
    margin-top: 16px;
    padding-top: 16px;
}

.top-balance-item__balance {
    font-weight: 600;
}

.finance-transfer-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.finance-transfer-heading__address {
    font-size: 12px;
    color: #A2B2D8;
}

.error-message {
    font-size: 12px;
    color: #FE636D;
}

.error-message::first-letter {
    text-transform: capitalize;
}