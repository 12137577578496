.table-holder {
  display: flex;
  flex-direction: column;
}

.table-content {
  width: 100%;
  min-width: 100%;
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

.table-th {
  padding: 1rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  text-align: left;
  line-height: 1.25rem;
  font-weight: 500;
  color: #718096;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #e2e8f0;
}

.table-tr {
  transition: all 0.3s;
}

.table-tr:hover {
  background-color: #e2e8f0;
}

.table-td {
  padding: 1rem;
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  color: #222426;
}

.header-cell {
  display: flex;
  align-items: center;
}

.text-left {
  text-align: left;
}

.gap-2 {
  gap: 0.5rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.uppercase {
  text-transform: uppercase;
}
