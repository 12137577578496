.tier {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
}

.tier__input {
    padding: 10px;
    height: 26px;
}
