.dropdown {
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
}

.dropdown .ui-input {
    margin-top: 0;
    margin-bottom: 0;
}

.dropdown__list {
    position: absolute;
    left: 0;
    top: 47px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 8px 49px rgba(162, 178, 216, 0.65);
    border-radius: 8px;
    padding: 16px;
    z-index: 999;
}

.dropdown__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown__item:not(:last-child) {
    margin-bottom: 16px;
}

.dropdown__item-first {
    color: #3568DD;
}

.dropdown__arrow {
    position: absolute;
    right: 19px;
    top: 19px;
}

.dropdown__arrow_active {
    transform: rotate(180deg);
}

.dropdown__label {
    position: absolute;
    color: #3568DD;
    right: 40px;
    top: 10px;
}