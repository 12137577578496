.ui-checkbox {
    position: relative;
    padding-left: 26px;
    color: #A2B2D8;
    cursor: pointer;
}

.ui-checkbox_checked {
    color: #3568DD;
}

.ui-checkbox__input {
    display: none;
}

.ui-checkbox__checkmark {
    border: 1px solid #A2B2D8;
    border-radius: 50%;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 2px;
}

.ui-checkbox__input:checked + .ui-checkbox__checkmark {
    background: #3568DD;
    border-color: #3568DD;
}
